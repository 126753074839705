var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c("vue-speedometer", {
            attrs: {
              width: _vm.gaugeWidth,
              height: _vm.gaugeWidth / 2 + 10,
              needleHeightRatio: 0.7,
              segments: 4,
              customSegmentStops: Object.values(_vm.scoreBoundaries),
              maxValue: 100,
              value: _vm.disabled ? _vm.defaultGaugeScore : _vm.score,
              segmentColors: _vm.segmentColors,
              ringWidth: _vm.gaugeWidth / 10,
              currentValueText: "",
              needleTransitionDuration: _vm.disabled ? 0 : 3333,
              needleTransition: _vm.disabled ? "" : "easeElastic",
              needleColor: _vm.disabled
                ? _vm.$vuetify.theme.currentTheme.disabled
                : _vm.$vuetify.theme.currentTheme.primary,
              labelFontSize: "0",
              forceRender: ""
            }
          })
        ],
        1
      ),
      _c("v-row", { attrs: { justify: "center" } }, [
        _c(
          "p",
          {
            staticClass: "text-h6",
            class: _vm.scoreColor + "--text",
            attrs: { id: "score" }
          },
          [_vm._v(" " + _vm._s(_vm.percentageScore) + " ")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }