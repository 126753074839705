<template>
  <div>
    <v-row justify="center">
      <vue-speedometer
        :width="gaugeWidth"
        :height="gaugeWidth / 2 + 10"
        :needleHeightRatio="0.7"
        :segments="4"
        :customSegmentStops="Object.values(scoreBoundaries)"
        :maxValue="100"
        :value="disabled ? defaultGaugeScore : score"
        :segmentColors="segmentColors"
        :ringWidth="gaugeWidth / 10"
        currentValueText=""
        :needleTransitionDuration="disabled ? 0 : 3333"
        :needleTransition="disabled ? '' : 'easeElastic'"
        :needleColor="
          disabled
            ? $vuetify.theme.currentTheme.disabled
            : $vuetify.theme.currentTheme.primary
        "
        labelFontSize="0"
        forceRender
      />
    </v-row>
    <v-row justify="center">
      <p id="score" class="text-h6" :class="scoreColor + '--text'">
        {{ percentageScore }}
      </p>
    </v-row>
  </div>
</template>

<script>
import VueSpeedometer from "vue-speedometer";

export default {
  name: "DeskAssessmentResultVisual",
  components: {
    VueSpeedometer
  },
  props: {
    gaugeWidth: Number,
    score: Number,
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      defaultGaugeScore: 50,
      scoreBoundaries: {
        poor: 0,
        average: 35,
        good: 56,
        veryGood: 78,
        max: 100
      }
    };
  },
  computed: {
    percentageScore() {
      return !this.disabled && this.score !== null ? this.score + "%" : "/";
    },
    scoreColor() {
      if (this.disabled || this.score === null) {
        return "disabled";
      }

      switch (true) {
        case this.score >= this.scoreBoundaries.veryGood:
          return "vitrueDarkGreen";
        case this.score >= this.scoreBoundaries.good:
          return "vitrueBrightGreen";
        case this.score >= this.scoreBoundaries.average:
          return "vitrueYellow";
        case this.score >= this.scoreBoundaries.poor:
          return "vitrueRed";
      }
    },
    segmentColors() {
      if (this.disabled) {
        return [this.$vuetify.theme.currentTheme.disabled];
      }
      return [
        this.$vuetify.theme.currentTheme.vitrueRed,
        this.$vuetify.theme.currentTheme.vitrueYellow,
        this.$vuetify.theme.currentTheme.vitrueBrightGreen,
        this.$vuetify.theme.currentTheme.vitrueDarkGreen
      ];
    }
  }
};
</script>
